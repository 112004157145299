import Echo from "laravel-echo";
import { isEmpty } from "lodash";
import Pusher from "pusher-js";
//import { useAuthStore } from '~/store/auth'

export default defineNuxtPlugin({
	name: "Echo",
	parallel: true,
	async setup(nuxtApp) {
		if (!process.client) return;

		const {
			public: { environment },
		} = useRuntimeConfig();

		const options = {
			enabledTransports: ["ws", "wss"],
			/*auth: {
        withCredentials: true,
        headers: {
          Authorization: useAuthStore().isAuthenticated ? useAuthStore().authorizationHeader : undefined,
        },
      },*/
		};

		if (isEmpty(options.key)) {
			return;
		}

		if (["development", "local", "staging"].includes(environment)) {
			Pusher.logToConsole = true;
		}

		return {
			provide: {
				Echo: new Echo(options),
			},
		};
	},
});
